import { useState, useEffect } from 'react';

const MOBILE_WIDTH_THRESHOLD = 640; // You can adjust this threshold as needed

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH_THRESHOLD);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < MOBILE_WIDTH_THRESHOLD);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return {isMobile};
};

export default useIsMobile;